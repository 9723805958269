import {graphql} from 'gatsby';
import React from 'react';

import {MongoDestination, getFlagEmoji} from '@travelfreedom/shared';

import {
  buildMapDataFromVisaLists,
  Layout,
  TableListEntry,
  ReportErrorButton,
  SEO,
  WorldMap,
} from '../components';
import {Destination, VisaLists} from '../library/common';

interface DestinationProps {
  data: {mongodbTravelfreedomDestinations: MongoDestination};
}

export const DestinationTemplate = ({data}: DestinationProps): JSX.Element => {
  const destination = Destination.fromJson(data.mongodbTravelfreedomDestinations);

  const tableClasses = 'table-auto w-full max-w-7xl mx-auto mb-2 bg-white';

  return (
    <Layout>
      <SEO
        title={`${getFlagEmoji(destination.code)} ${destination.name}`}
        pathname={Destination.hrefPath(destination)}
      />
      <h1 className="font-bold text-gray-500 mt-3 mb-2 max-w-7xl mx-auto">
        {getFlagEmoji(destination.code)} {destination.name}
      </h1>

      <div className="max-w-7xl mx-auto p-2 border rounded border-gray-300 bg-white min-w-0">
        <div>
          {destination.name} lets ordinary passport holders from{' '}
          {VisaLists.travelfreedom(destination)} countries travel to it without a prior visa.
        </div>
        <div>{destination.explanation}</div>
      </div>

      <div className="flex max-w-7xl mx-auto">
        <ReportErrorButton />
      </div>

      <div className="bg-white my-2 max-w-7xl mx-auto">
        <WorldMap worldMapData={buildMapDataFromVisaLists(destination)} showLegend />
      </div>

      {/* <GoogleAd /> */}

      {destination.visafreeList.length > 0 && (
        <table className={tableClasses}>
          <thead>
            <tr className="bg-green-600 text-white">
              <th colSpan={2} className="p-2">
                Visa-Free/Visa-on-arrival
              </th>
            </tr>
          </thead>
          <tbody className="bg-green-100 divide-y divide-gray-200">
            {destination.visafreeList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={destination.conditions[code]}
                targetType="passport"
              />
            ))}
          </tbody>
        </table>
      )}
      {destination.etaList.length > 0 && (
        <table className={tableClasses}>
          <thead>
            <tr className="bg-yellow-600 text-white">
              <th colSpan={2} className="p-2">
                Electronic Authorization
              </th>
            </tr>
          </thead>
          <tbody className="bg-yellow-100 divide-y divide-gray-200">
            {destination.etaList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={destination.conditions[code]}
                targetType="passport"
              />
            ))}
          </tbody>
        </table>
      )}
      {destination.visapriorList.length > 0 && (
        <table className={tableClasses}>
          <thead>
            <tr className="bg-red-600 text-white">
              <th colSpan={2} className="p-2">
                Visa required prior to arrival
              </th>
            </tr>
          </thead>
          <tbody className="bg-red-100 divide-y divide-gray-200">
            {destination.visapriorList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={destination.conditions[code]}
                targetType="passport"
              />
            ))}
          </tbody>
        </table>
      )}
      {destination.bannedList.length > 0 && (
        <table className={tableClasses}>
          <thead>
            <tr className="bg-gray-600 text-white">
              <th colSpan={2} className="p-2">
                Entry denied
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-300 divide-y divide-gray-200">
            {destination.bannedList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={destination.conditions[code]}
                targetType="passport"
              />
            ))}
          </tbody>
        </table>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mongodbTravelfreedomDestinations(fields: {slug: {eq: $slug}}) {
      globalCode
      name
      explanation
      visaList {
        visaprior
        visafree
        eta
        banned
      }
      conditions {
        key
        value
      }
    }
  }
`;

export default DestinationTemplate;
